import Joi from 'joi';

const constraints = {};

export const purchaseValidation = {
  constraints,
  schema: Joi.object({
    count: Joi.number().positive(),
  }),
};
