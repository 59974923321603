import {Language} from '../../../enums';

export type QuestionnaireSubmission = {
  date: Date;
  questionId: number;
  selectedAnswerId: number;
  isCorrect: boolean;
  language: Language;
}

export class QuestionnaireSubmissionDto {
  date!: Date;
  questionId!: number;
  selectedAnswerId!: number;
  isCorrect!: boolean;
  language!: Language;
}
