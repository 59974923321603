import Joi from 'joi';

const constraints = {
  name: {maxLength: 30},
};

export const questionCatalogueValidation = {
  constraints,
  schema: Joi.object({
    name: Joi.string().label('name')
      .max(constraints.name.maxLength)
      .required(),
    learningObjectiveTreeId: Joi.number().label('learningObjectiveTree').required(),
  }),
};
