import {LearningObjectiveTreeData} from '.';
import {Model} from '../web';

export class LearningObjectiveTree extends Model {
  static entity = 'learning-objective-trees';
  declare public data: LearningObjectiveTreeData;

  static get translationKey() {
    return 'learningObjectiveTree';
  }
}
