import {AxiosInstance, AxiosRequestConfig} from 'axios';
import deepmerge from 'deepmerge';
import {Schema} from 'joi';
import parseDuration from 'parse-duration';
import {getValidationMessages} from '../validation';
import {ModelRequestOptions, RequestCache} from '../web';

export abstract class Model {
  public static entity: string;
  public static validation: {
    constraints: { [field: string]: any },
    schema: any,
    createSchema?: any,
  };
  protected static cache: RequestCache;
  protected static $api: AxiosInstance;
  protected static $t: (key: string, values?: any) => string;
  protected static $tc: (key: string, amount?: number) => string;
  protected static $i18n: any;
  private static defaultModelRequestOptions: ModelRequestOptions = {};

  protected data: any;

  constructor(data?: any) {
    this.data = data;
  }

  static setContext(context: any) {
    this.$api = context.$api;
    this.$t = context.$t.bind(context);
    this.$tc = context.$tc.bind(context);
    this.$i18n = context.$i18n;

    if (!this.cache) {
      this.cache = new RequestCache();
    }
  }

  public static get commentableType(): string {
    return this.entity.substr(0, this.entity.length - 1);
  }

  public static get apiPath(): string {
    return `/api/${this.entity}`;
  }

  public static get translationKey(): string {
    return this.entity.substr(0, this.entity.length - 1);
  }

  static async fetch(options: ModelRequestOptions = {}): Promise<any> {
    options = deepmerge(this.defaultModelRequestOptions, options);
    return await this.performRequest(this.apiPath, options);
  }

  static async fetchAll(options: ModelRequestOptions = {}): Promise<any> {
    options = deepmerge(this.defaultModelRequestOptions, options);
    return await this.performRequest(`${this.apiPath}/all`, options);
  }

  static async fetchSelectOptions(options: ModelRequestOptions = {}): Promise<any> {
    options = deepmerge(this.defaultModelRequestOptions, options);
    return await this.performRequest(`${this.apiPath}/options`, options);
  }

  static async fetchOne(id: number | string, options: ModelRequestOptions = {}): Promise<any> {
    options = deepmerge(this.defaultModelRequestOptions, options);
    return await this.performRequest(`${this.apiPath}/${id}`, options);
  }

  static async fetchDetails(id: number | string): Promise<any> {
    return await this.performRequest(`${this.apiPath}/${id}/details`);
  }

  static async asyncCreate(data: any, options?: ModelRequestOptions): Promise<any> {
    const config = this.createRequestConfig(options);
    const response = await this.$api.post(this.apiPath, data, config);
    return response.data;
  }

  static async update(id: number, data: any, options?: ModelRequestOptions): Promise<any> {
    const config = this.createRequestConfig(options);
    const response = await this.$api.put(`${this.apiPath}/${id}`, data, config);
    return response.data;
  }

  static async delete(id: number, options?: ModelRequestOptions): Promise<any> {
    const config = this.createRequestConfig(options);
    const response = await this.$api.delete(`${this.apiPath}/${id}`, config);
    return response.data;
  }

  static validate(field: string, input: any): boolean {
    return this.ruleSet(this.$i18n?.locale)[field]?.reduce((result, rule) => result && rule(input) === true, true);
  }

  static ruleSet(locale: string, schema = this.validation.schema): any {
    const messages = getValidationMessages(locale);
    const schemaKeys: string[] = Array.from((schema as any)._ids._byKey.keys());
    return schemaKeys.reduce((ruleSet, key) => Object.assign(ruleSet, {[key]: this.createRules(schema, key, messages)}), {});
  }

  static createRules(schema: Schema, key: string, messages = {}): any[] {
    return [(input: any) => {
      const result = schema.extract(key).validate(input, {messages});
      return result.error?.message || true;
    }];
  }

  protected static async performRequest(path: string, options?: ModelRequestOptions): Promise<any> {
    const config = this.createRequestConfig(options);

    if (options?.cache && this.cache.has(path, config, this.getMaxAgeInMs(options?.maxAge))) {
      return this.cache.get(path, config);
    }

    const response = await this.$api.get(path, config);

    if (options?.cache) {
      this.cache.set(path, config, response.data);
    }

    if (options?.returnResponse) {
      return response;
    }

    return response.data;
  }

  private static createRequestConfig(options?: ModelRequestOptions): AxiosRequestConfig {
    if (!options) {
      return {};
    }

    const params: any = {};

    if (options.pagination) {
      const pagination = options.pagination;
      params.offset = (pagination.page - 1) * pagination.itemsPerPage;
      params.limit = pagination.itemsPerPage;
      params.order = pagination.sortBy.map((field: string, index: number) => `${field}:${pagination.sortDesc[index] ? 'DESC' : 'ASC'}`);
    }

    if (options.query) {
      params.q = options.query;
    }

    Object.assign(params, options.where, {relations: options.relations}, options.params);

    return {
      params,
    };
  }

  private static getMaxAgeInMs(maxAge?: string): number {
    if (!maxAge) {
      return 0;
    }
    const ms = parseDuration(maxAge);
    return ms || 0;
  }

  toString(): string {
    const className = Object.getPrototypeOf(this).constructor.name;
    return `${className}#${this.data.id}`;
  }

  toJson(): string {
    return JSON.stringify(this.data, null, 2);
  }

  protected get language() {
    return Model.$t('language.' + this.data.language);
  }
}
