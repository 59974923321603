import Joi from 'joi';
import {PublishedState, VersionType} from '../enums';

const constraints = {
  language: {valid: ['de', 'en'], maxLength: 2},
  publishedState: {valid: Object.values(PublishedState)},
};

export const commonValidation = {
  constraints,
  rules: {
    language: Joi.string().label('language')
      .valid(...constraints.language.valid)
      .required(),
    publishedState: Joi.string().label('publishedState')
      .valid(...constraints.publishedState.valid)
      .required(),
  },
};

export const versionSchema = Joi.object({
  type: Joi.string().valid(...Object.values(VersionType)),
  name: Joi.string().min(5),
  currentVersion: Joi.string().min(5),
  description: Joi.string().min(10),
  resetRating: Joi.boolean().optional(),
  reviewerId: Joi.number().positive().allow(null).optional(),
  reviewerName: Joi.string().allow(null).optional(),
});
