import tlds from '@sideway/address/lib/tlds';
import Joi from 'joi';

const constraints = {
  name: {maxLength: 60},
  customerNumber: {maxLength: 16},
  taxId: {maxLength: 14},
  logo: {maxLength: 50},
  email: {maxLength: 100},
  phone: {maxLength: 25},
  fax: {maxLength: 25},
  website: {maxLength: 100},
  country: {maxLength: 2},
  state: {maxLength: 60},
  zipCode: {maxLength: 10},
  city: {maxLength: 60},
  addressLine1: {maxLength: 100},
  addressLine2: {maxLength: 100},
  note: {maxLength: 1000},
  certificateNumber: {maxLength: 20},
  primaryContact: {maxLength: 100},
};

export const organizationValidation = {
  constraints,
  schema: Joi.object({
    name: Joi.string().label('name')
      .max(constraints.name.maxLength)
      .required(),
    email: Joi.string().label('email')
      .email({tlds: {allow: tlds}})
      .max(constraints.email.maxLength)
      .required(),
    note: Joi.string().label('note')
      .max(constraints.note.maxLength)
      .allow('')
      .optional(),
    customerNumber: Joi.string().label('customerNumber')
      .max(constraints.customerNumber.maxLength).allow(''),
    taxId: Joi.string().label('taxId')
      .max(constraints.taxId.maxLength).allow(''),
    discount: Joi.number().label('discount').positive().allow(0).max(100),
  }),
};
