import {EducationData} from '.';
import {Model} from '../web';

export class Education extends Model {
  static entity = 'educations';
  declare public data: EducationData;

  get title() {
    return `${this.data.licenceId} (${this.data.language})`;
  }

  static async fetchForELearningAccount(eLearningAccountId: number) {
    const response = await Education.$api.get(Education.apiPath + '/e-learning/' + eLearningAccountId);
    return response.data;
  }
}
