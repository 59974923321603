import {LearningObjective} from '@cat-europe/common/web';
import {Module} from 'vuex';
import {RootState} from '~/store';


export interface LearningObjectivesState {
  isSet: boolean,
  items: LearningObjective[],
  open: LearningObjective[],
  active: LearningObjective[],
}

export const learningObjectiveStore: Module<LearningObjectivesState, RootState> = {
  namespaced: true,
  state: {
    isSet: false,
    items: [],
    open: [],
    active: [],
  },
  mutations: {
    setState(state, s) {
      state.items = s.items;
      state.open = s.open;
      state.active = s.active;
      state.isSet = true;
    },
  },
  actions: {},
  getters: {},
};
