import Joi from 'joi';

const constraints = {
  number: {maxLength: 120},
};

export const invoiceValidation = {
  constraints,
  schema: Joi.object({
    number: Joi.string().label('number')
      .max(constraints.number.maxLength)
      .required(),
    sum: Joi.number().positive(),
  }),
};
