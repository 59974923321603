import {QuestionCatalogueData, questionCatalogueValidation} from '.';
import {Model} from '../web';

export class QuestionCatalogue extends Model {
  static entity = 'question-catalogues';
  static validation = questionCatalogueValidation;
  declare public data: QuestionCatalogueData;

  public static get translationKey(): string {
    return 'questionCatalogue';
  }
}
