import {TagData} from '.';
import {Model} from '../web';

export class Tag extends Model {
  static entity = 'tags';
  declare public data: TagData;

  static suggestionCache = {};

  public static async findSuggestions(input: string) {
    const lowerInput = input.toLowerCase();
    if (this.suggestionCache[lowerInput]) {
      return this.suggestionCache[lowerInput];
    }

    const inclusionKey = Object.keys(this.suggestionCache).find(key => lowerInput.startsWith(key));
    if (inclusionKey) {
      return this.suggestionCache[inclusionKey].filter(tag => tag.name.toLowerCase().startsWith(lowerInput));
    }
    const response = await this.$api.get(`/api/tags/suggestions`, {params: {name: lowerInput}});
    this.suggestionCache[lowerInput] = response.data;
    return this.suggestionCache[lowerInput];
  }
}
