export enum ChangeCollection {
  QuestionMasterData = 'question-changes',
  QuestionLicences = 'question-licences-changes',
  QuestionLocalizations = 'question-localizations-changes',
  QuestionResources = 'question-resources-changes',
  ResourceMasterData = 'resource-changes',
  ResourceLocalizations = 'resource-localizations-changes',
  ContentBlockMasterData = 'content-block-changes',
  ContentBlockLocalizations = 'content-block-localizations-changes',
  ScriptMasterData = 'script-changes',
  ScriptTitlePages = 'script-title-pages-changes',
  ScriptStructure = 'script-structure-changes',
}
