import {AxiosRequestConfig} from 'axios';

export interface CacheEntry {
  time: number;
  data: any;
}

export class RequestCache {
  private cache: { [key: string]: CacheEntry } = {};

  public get(path: string, config: AxiosRequestConfig): any {
    const key = RequestCache.createKey(path, config);
    return this.cache[key]?.data;
  }

  public has(path: string, config: AxiosRequestConfig, maxAge = 0): boolean {
    const key = RequestCache.createKey(path, config);
    const entry = this.cache[key];
    return !!entry && (entry.time + maxAge) > Date.now();
  }

  public set(path: string, config: AxiosRequestConfig, data: any): void {
    const key = RequestCache.createKey(path, config);
    this.cache[key] = {time: Date.now(), data};
  }

  private static createKey(path: string, config: AxiosRequestConfig): string {
    return path + JSON.stringify(config);
  }
}
