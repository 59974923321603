import {PurchaseData} from '.';
import {Model} from '../web';
import {purchaseValidation} from './purchase.validation';

export class Purchase extends Model {
  static entity = 'purchases';
  static validation = purchaseValidation;

  declare public data: PurchaseData;
}
