import tlds from '@sideway/address/lib/tlds';
import Joi from 'joi';

const constraints = {
  userId: {},
  tokenVersion: {},
  lastLoginAt: {},
  createdAt: {},
  updatedAt: {},
  jobTitle: {maxLength: 50},
  email: {maxLength: 50},
  phone: {maxLength: 50},
  fax: {maxLength: 50},
};

export const organizationAccountValidation = {
  constraints,
  schema: Joi.object({
    userId: Joi.number().label('userId'),
    organizationId: Joi.number().label('organizationId').required(),
    email: Joi.string().label('email')
      .email({tlds: {allow: tlds}})
      .max(constraints.email.maxLength),
    phone: Joi.string().label('phone')
      .max(constraints.phone.maxLength),
    fax: Joi.string().label('fax')
      .max(constraints.fax.maxLength),
    jobTitle: Joi.string().label('jobTitle')
      .max(constraints.jobTitle.maxLength),
    tokenVersion: Joi.number().label('tokenVersion'),
    lastLoginAt: Joi.date().label('lastLoginAt'),
    createdAt: Joi.date().label('createdAt'),
    updatedAt: Joi.date().label('updatedAt'),
  }),
};
