import {VersionType} from '../enums';

export function bumpVersion(versionName: string, type: VersionType) {
  const parts = versionName.split('.').map(part => Number(part.trim()));
  switch (type) {
    case VersionType.Major:
      parts[0]++;
      parts[1] = 0;
      parts[2] = 0;
      break;
    case VersionType.Minor:
      parts[1]++;
      parts[2] = 0;
      break;
    case VersionType.Fix:
      parts[2]++;
      break;
  }
  return parts.join('.');
}

export function camelToKebabCase(input: string) {
  return input.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

