import Joi from 'joi';

const constraints = {
  title: {maxLength: 100},
};

export const questionnaireValidation = {
  constraints,
  schema: Joi.object({
    title: Joi.string().label('title').max(constraints.title.maxLength).required(),
    licenceId: Joi.number().label('licenceId').required(),
    learningObjectiveTreeId: Joi.number().label('learningObjectiveTreeId').required(),
    questionCatalogueId: Joi.string().label('questionCatalogueId').required(),
    questionIds: Joi.array().items(Joi.number()).required(),
  }),
};
