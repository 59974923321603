import Joi from 'joi';

const constraints = {
  userId: {},
  gender: {maxLength: 30},
  title: {maxLength: 30},
  firstName: {maxLength: 40},
  lastName: {maxLength: 40},
  email: {maxLength: 100},
  phoneNr: {maxLength: 25},
  faxNr: {maxLength: 25},
  country: {maxLength: 60},
  state: {maxLength: 60},
  zipCode: {maxLength: 10},
  city: {maxLength: 60},
  addressLine1: {maxLength: 100},
  addressLine2: {maxLength: 100},
  createdAt: {},
  updatedAt: {},
};

export const contactProfileValidation = {
  constraints,
  schema: Joi.object({
    userId: Joi.number().allow(null, '').label('userId'),
    gender: Joi.string().max(constraints.gender.maxLength).allow(null, '').label('gender'),
    title: Joi.string().max(constraints.title.maxLength).allow(null, '').label('title'),
    firstName: Joi.string().max(constraints.firstName.maxLength).allow(null, '').label('firstName'),
    lastName: Joi.string().max(constraints.lastName.maxLength).allow(null, '').label('lastName'),
    email: Joi.string().max(constraints.email.maxLength).allow(null, '').email({tlds: {allow: false}}).label('email'),
    phoneNr: Joi.string().max(constraints.phoneNr.maxLength).allow(null, '').label('phoneNr'),
    country: Joi.string().max(constraints.country.maxLength).allow(null, '').label('country'),
    birthDate: Joi.date().label('birthDate').allow(null),
    state: Joi.string().max(constraints.state.maxLength).allow(null, '').label('state'),
    zipCode: Joi.string().max(constraints.zipCode.maxLength).allow(null, '').label('zipCode'),
    city: Joi.string().max(constraints.city.maxLength).allow(null, '').label('city'),
    addressLine1: Joi.string().max(constraints.addressLine1.maxLength).allow(null, '').label('addressLine1'),
    addressLine2: Joi.string().max(constraints.addressLine2.maxLength).allow(null, '').label('addressLine2'),
    createdAt: Joi.date().label('createdAt'),
    updatedAt: Joi.date().label('updatedAt'),
  }),
};
