import Joi from 'joi';

const constraints = {
  userId: {},
  tokenVersion: {},
  lastLoginAt: {},
  createdAt: {},
  updatedAt: {},
};

export const eLearningAccountValidation = {
  constraints,
  schema: Joi.object({
    userId: Joi.number().label('userId'),
    tokenVersion: Joi.number().label('tokenVersion'),
    lastLoginAt: Joi.date().label('lastLoginAt'),
    createdAt: Joi.date().label('createdAt'),
    updatedAt: Joi.date().label('updatedAt'),
  }),
};
