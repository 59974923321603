import Joi from 'joi';
import {commonValidation} from '../../validation';

const constraints = {
  title: {maxLength: 512},
  subTitle: {maxLength: 512},
  number: {maxLength: 32},
};

export const scriptTitlePageValidation = {
  constraints,
  schema: Joi.object({
    title: Joi.string().label('title')
      .max(constraints.title.maxLength)
      .required(),
    subTitle: Joi.string().label('subTitle')
      .max(constraints.subTitle.maxLength)
      .required(),
    language: commonValidation.rules.language,
    scriptId: Joi.number().positive().integer().label('scriptId'),
  }),
};
