import {CountryData, OrganizationData, ProductData} from '../models';

export class OrderCore {
  constructor(items, organization, country) {
    this.items = items;
    this.organization = organization;
    this.country = country;
  }

  country: CountryData;
  organization: OrganizationData;
  items: { amount: number, product: ProductData }[] = [];

  get subTotal() {
    return this.items.reduce((sum, item) => sum + item.amount * item.product.price, 0);
  }

  get discount() {
    return this.organization.discount;
  }

  get vatExempt() {
    return this.organization.vatExempt;
  }

  get isReverseChargeCountry() {
    return !!this.country?.reverseCharge;
  }

  get discountedSubTotal() {
    return this.subTotal + this.totalDiscountValue;
  }

  get totalDiscountValue() {
    return -this.items.reduce((value, item) => value + (item.amount * item.product.price * this.discount / 100), 0);
  }

  get totalTaxValue() {
    if (!this.country || this.country.reverseCharge || this.organization.vatExempt) {
      return 0;
    }
    return this.items.reduce((value, item) => {
      const itemSum = item.amount * item.product.price;
      const discount = this.discount / 100;
      const discountedItemSum = itemSum - (itemSum * discount);
      const tax = this.tax(item.product) / 100;
      return value + (discountedItemSum * tax);
    }, 0);
  }

  tax(product: ProductData) {
    if (!this.country || this.country.reverseCharge || this.organization.vatExempt) {
      return 0;
    }
    return product.reducedTax ? this.country.reducedTax : this.country.tax;
  }

  taxValue(product: ProductData) {
    const discountedPrice = product.price + this.discountValue(product);
    return discountedPrice * this.tax(product) / 100;
  }

  discountValue(product: ProductData) {
    return -(product.price * this.discount / 100);
  }

  get total() {
    return (this.vatExempt || this.isReverseChargeCountry) ? this.discountedSubTotal : this.discountedSubTotal + this.totalTaxValue;
  }

  get hasMixedTaxes() {
    const result = this.items.reduce((result, item) => ({
      reduced: result.reduced || item.product.reducedTax,
      normal: result.normal || !item.product.reducedTax,
    }), {reduced: false, normal: false});
    return result.reduced && result.normal;
  }

  // decreaseAmount(productId: number) {
  //   this.$store.commit('basket/decreaseAmount', productId);
  // }
  //
  // increaseAmount(productId: number) {
  //   this.$store.commit('basket/increaseAmount', productId);
  // }
}
