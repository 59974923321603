import Joi from 'joi';
import {LicenceType, ResourceType} from '../../enums';

const constraints = {
  title: {maxLength: 255},
};

export const resourceValidation = {
  constraints,
  schema: Joi.object({
    title: Joi.string().label('title').max(constraints.title.maxLength).required(),
    type: Joi.string().label('type').valid(...Object.values(ResourceType)).required(),
    licenceType: Joi.string().label('licenceType').valid(...Object.values(LicenceType)).required(),
    sourceId: Joi.number().label('sourceId').positive().integer().required(),
  }),
  createSchema: Joi.object({
    title: Joi.string().label('title').max(constraints.title.maxLength).required(),
    type: Joi.string().label('type').valid(...Object.values(ResourceType)).required(),
    licenceType: Joi.string().label('licenceType').valid(...Object.values(LicenceType)).required(),
    sourceId: Joi.number().label('sourceId').positive().integer().required(),
    public: Joi.boolean().label('public').required(),
    external: Joi.boolean().label('external').required(),
    localizations: Joi.when('external', {
      is: true,
      then: Joi.array().items(Joi.object({
        language: Joi.string().required(),
        origin: Joi.string().allow('').required(),
        description: Joi.string().allow('').required(),
        src: Joi.string().uri().required(),
        filename: Joi.string().allow('').optional(),
      })).min(1),
      otherwise: Joi.array().items(Joi.object({
        language: Joi.string().required(),
        origin: Joi.string().allow('').required(),
        description: Joi.string().allow('').required(),
        src: Joi.string().uri().optional().allow(''),
        uploadIdentifier: Joi.string().required(),
        filename: Joi.string().allow('').optional(),
      })).min(1),
    }),
    tagIds: Joi.array().items(Joi.number().positive().integer()),
    learningObjectiveIds: Joi.array().items(Joi.number().positive().integer()),
  }),
};
