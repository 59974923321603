import {LicenceData} from '.';
import {ModelRequestOptions} from '../../web';
import {Model} from '../web';

export class Licence extends Model {
  static entity = 'licences';
  declare public data: LicenceData;

  static async fetchLearningObjectives(licenceId: number) {
    const data = await this.performRequest(`/api/licences/${licenceId}/learning-objectives`);
    return data.map(data => new Licence(data));
  }

  static async fetchLearningObjectiveIds(licenceId: number, options: ModelRequestOptions = {}) {
    return await this.performRequest(`/api/licences/${licenceId}/learning-objective-ids`, options);
  }
}
