import {PurchaseInviteData} from '../purchase-invite';
import {AccessPassType} from './AccessPassType';

export interface AccessPassData {
  id: number;
  eLearningAccountId: number;
  questionCatalogueId: number;
  purchaseInviteId: number;
  type: AccessPassType;
  validFrom: Date;
  purchaseInvite?: PurchaseInviteData;
  expiresAt: Date;
  updatedAt: Date;
  createdAt: Date;
}

export class AccessPassDto {
  id!: number;
  eLearningAccountId!: number;
  questionCatalogueId!: number;
  type!: AccessPassType;
  validFrom!: Date;
  expiresAt!: Date;
  updatedAt!: Date;
  createdAt!: Date;
}
