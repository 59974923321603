import Joi from 'joi';

const constraints = {
  picture: {maxLength: 120},
  email: {maxLength: 100},
};

export const authorProfileValidation = {
  constraints,
  schema: Joi.object({
    picture: Joi.string().label('picture')
      .max(constraints.picture.maxLength),
    email: Joi.string().label('email')
      .email({tlds: {allow: false}})
      .max(constraints.email.maxLength)
      .allow(null, ''),
  }),
};
