import tlds from '@sideway/address/lib/tlds';
import Joi from 'joi';

const constraints = {
  name: {maxLength: 50},
  email: {maxLength: 100},
};

export const sourceValidation = {
  constraints,
  schema: Joi.object({
    name: Joi.string().label('name')
      .max(constraints.name.maxLength)
      .allow(''),
    email: Joi.string().label('email')
      .max(constraints.email.maxLength)
      .email({tlds: {allow: tlds}})
      .required(),
  }),
};
