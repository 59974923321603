import Joi from 'joi';
import {AccessPassType} from './AccessPassType';

const constraints = {
  type: {maxLength: 20, valid: Object.values(AccessPassType)},
};

export const accessPassValidation = {
  constraints,
  schema: Joi.object({
    eLearningAccountId: Joi.number().label('eLearningAccountId').required(),
    type: Joi.string().label('type')
      .max(constraints.type.maxLength)
      .valid(...constraints.type.valid)
      .required(),
    questionCatalogueId: Joi.number().required(),
    validFrom: Joi.string().label('validFrom')
      .required(),
    expiresAt: Joi.string().label('expiresAt')
      .required(),
  }),
  createSchema: {},
};

// accessPassValidation.createSchema = {
//   ...accessPassValidation.schema,
// };
