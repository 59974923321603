import Joi from 'joi';
import {commonValidation} from '../../validation';

const constraints = {
  title: {maxLength: 200},
  version: {maxLength: 50},
};

export const scriptValidation = {
  constraints,
  schema: Joi.object({
    title: Joi.string().label('title')
      .max(constraints.title.maxLength)
      .required(),
    version: Joi.string().label('version')
      .max(constraints.version.maxLength)
      .required(),
    language: commonValidation.rules.language,
    publishedState: commonValidation.rules.publishedState,
    authorProfileId: Joi.number().label('authorProfileId'),
    licenceId: Joi.number().label('licenceId'),
    contentBlocks: Joi.array().label('contentBlocks'),
  }),
};
