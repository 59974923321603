import {EventData} from '.';
import {Model} from '../web';

export class Event extends Model {
  static entity = 'events';
  declare public data: EventData;

  toCalendarItem() {
    return {
      id: this.data.id,
      color: 'blue',
      type: 'event',
      description: this.data.description,
      name: this.data.title,
      start: new Date(this.data.startDate),
      end: new Date(this.data.endDate),
    };
  }
}
