import {ScriptData} from '..';
import {Model} from '../web';

export class Script extends Model {
  static entity = 'scripts';
  declare public data: ScriptData;

  get name() {
    return this.data.name || `${Script.$tc('p.script')} (ID: ${this.data.id})`;
  }

  get language() {
    return super.language;
  }
}
