import {LearningObjectiveData, learningObjectiveValidation} from '.';
import {Model} from '../web';

export class LearningObjective extends Model {
  static entity = 'learning-objectives';
  static validation = learningObjectiveValidation;
  declare public data: LearningObjectiveData;

  get name(): string {
    return this.data.title || `${LearningObjective.$tc('p.learningObjective')} (ID: ${this.data.id})`;
  }

  get children() {
    if (!this.data) {
      return [];
    }
    return this.data.level < 5 ? this.data.children : undefined;
  }

  set children(children) {
    this.data.children = children;
  }

  static get translationKey() {
    return 'learningObjective';
  }

  static async fetchLicences(learningObjectiveId: number) {
    const response = await LearningObjective.$api.get(`/api/learning-objectives/${learningObjectiveId}/licences`);
    return response.data;
  }

  static async fetchQuestions(learningObjectiveId: number) {
    const response = await LearningObjective.$api.get(`/api/learning-objectives/${learningObjectiveId}/questions`);
    return response.data;
  }

  static async fetchQuestionRelations() {
    const response = await LearningObjective.$api.get(`/api/learning-objectives/question-relations`);
    return response.data;
  }

  static async fetchRecursive(options) {
    const response = await LearningObjective.$api.get(`/api/learning-objectives/recursive`, {params: options.where});
    return response.data;
  }

  static async fetchRecursiveUpdateCache(options) {
    const response = await LearningObjective.$api.get(`/api/learning-objectives/recursive`, {params: {...options.where, updateCache: true}});
    return response.data;
  }
}
