import Joi from 'joi';

const constraints = {
  locale: {maxLength: 2},
};

export const countryValidation = {
  constraints,
  schema: Joi.object({
    locale: Joi.string().label('locale')
      .max(constraints.locale.maxLength)
      .required(),
    tax: Joi.number().positive().allow(0).max(100),
    reducedTax: Joi.number().positive().allow(0).max(100),
    reverseCharge: Joi.boolean(),
  }),
};
