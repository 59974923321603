import {PurchaseInviteData} from '.';
import {Model} from '../web';
import {purchaseInviteValidation} from './purchase-invite.validation';

export class PurchaseInvite extends Model {
  static entity = 'purchase-invites';
  static validation = purchaseInviteValidation;

  declare public data: PurchaseInviteData;
}
