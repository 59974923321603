import Joi from 'joi';
import {organizationValidation} from '../organization';

const constraints = {
  number: {maxLength: 60},
  status: {maxLength: 30},
  invoiceStatus: {maxLength: 30},
  customerNumber: {maxLength: organizationValidation.constraints.customerNumber.maxLength},
  taxId: {maxLength: organizationValidation.constraints.taxId.maxLength},
};

export const orderValidation = {
  constraints,
  schema: Joi.object({
    number: Joi.string().label('number')
      .max(constraints.number.maxLength)
      .required(),
    sum: Joi.number().positive(),
  }),
};
