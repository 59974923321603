import {de} from './de';
import {en} from './en';

export const validationMessages = {de, en};

export function getValidationMessages(locale?: string) {
  switch (locale) {
    case 'de':
      return de;

    case 'en':
      return en;

    default:
      return en;
  }
}
