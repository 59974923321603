import Joi from 'joi';

const constraints = {
  text: {required: true, maxLength: 1000},
};

export const commentValidation = {
  constraints,
  schema: Joi.object({
    text: Joi.string().max(constraints.text.maxLength).label('text').required(),
  }),
};
