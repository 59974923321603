import countries from 'i18n-iso-countries';
import deCountries from 'i18n-iso-countries/langs/de.json';
import enCountries from 'i18n-iso-countries/langs/en.json';

countries.registerLocale(enCountries);
countries.registerLocale(deCountries);

export const countryMap = {
  de: countries.getNames('de'),
  en: countries.getNames('en'),
};

export const countryList = {
  de: Object.keys(countryMap.de).map(locale => ({value: locale, text: countryMap.de[locale]})),
  en: Object.keys(countryMap.en).map(locale => ({value: locale, text: countryMap.en[locale]})),
};

export function resolveCountry(languageLocale: string, countryLocale: string): string {
  return countryMap[languageLocale.toLowerCase()][countryLocale.toUpperCase()];
}
