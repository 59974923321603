import Joi from 'joi';
import {LicenceType} from '../../enums';

const constraints = {
  language: {valid: ['de', 'en']},
  name: {maxLength: 255},
  headline: {maxLength: 512},
  version: {regex: /\d+\.\d+\.\d+/},
};

export const contentBlockValidation = {
  constraints,
  schema: Joi.object({
    name: Joi.string().max(constraints.name.maxLength).label('name').required(),
    studyTime: Joi.number().allow(0).positive().integer().label('studyTime').optional(),
    sourceId: Joi.number().positive().integer().label('sourceId').required(),
    licenceType: Joi.string().valid(...Object.values(LicenceType)).label('licenceType').required(),
  }),
  createSchema: Joi.object({
    name: Joi.string().max(constraints.name.maxLength).label('name').required(),
    studyTime: Joi.number().allow(0).positive().integer().label('studyTime').optional(),
    sourceId: Joi.number().positive().integer().label('sourceId').required(),
    licenceType: Joi.string().valid(...Object.values(LicenceType)).label('licenceType').required(),
    licences: Joi.array().items(Joi.number().integer().positive()).label('licences').required(),
    isProseBlock: Joi.boolean().label('isProseBlock').required(),
    learningObjectiveIds: Joi.array().items(Joi.number().integer().positive()).label('learningObjectiveIds').required(),
    localizations: Joi.array().items(Joi.object({
      headline: Joi.string().max(constraints.headline.maxLength).required(),
      language: Joi.string().min(2).max(2).required(),
      content: Joi.string().allow('').required(),
    })).label('localizations').required(),
  }),
};
