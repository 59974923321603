import tlds from '@sideway/address/lib/tlds';
import Joi from 'joi';

const constraints = {
  position: {maxLength: 60},
  email: {maxLength: 100},
  phone: {minLength: 3, maxLength: 36},
  fax: {minLength: 3, maxLength: 36},
};

export const ltmsAccountValidation = {
  constraints,
  schema: Joi.object({
    position: Joi.string().label('position')
      .max(constraints.position.maxLength)
      .allow(null, ''),
    email: Joi.string().label('email')
      .email({tlds: {allow: tlds}})
      .max(constraints.email.maxLength)
      .allow(null, ''),
    phone: Joi.string().label('phone')
      .min(constraints.phone.minLength)
      .max(constraints.phone.maxLength)
      .allow(null, ''),
    fax: Joi.string().label('fax')
      .min(constraints.fax.minLength)
      .max(constraints.fax.maxLength)
      .allow(null, ''),
  }),
};
