import {ContentBlockData, contentBlockValidation} from '.';
import {Model} from '../web';


export class ContentBlock extends Model {
  static entity = 'content-blocks';
  static validation = contentBlockValidation;

  static get translationKey() {
    return 'contentBlock';
  }

  declare public data: ContentBlockData;
}
