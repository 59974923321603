import Vue from 'vue';
import {authStore} from '~/store/auth.store';

export class PostForm {
  open(url: string, data?: any, target = '_blank') {
    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', url);
    form.setAttribute('target', target);

    if (data) {
      const dataField = document.createElement('input');
      dataField.setAttribute('type', 'hidden');
      dataField.setAttribute('name', 'data');
      dataField.setAttribute('value', JSON.stringify(data));
      form.appendChild(dataField);
    }

    const tokenField = document.createElement('input');
    tokenField.setAttribute('type', 'hidden');
    tokenField.setAttribute('name', 'jwt');
    tokenField.setAttribute('value', authStore.state['accessToken']);
    form.appendChild(tokenField);

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  }
}

export class PostFormPlugin {
  static install(Vue) {
    Vue.prototype.$postForm = new PostForm();
  }
}

Vue.use(PostFormPlugin);

declare module 'vue/types/vue' {
  interface Vue {
    $postForm: PostForm;
  }
}
