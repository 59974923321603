import {ExaminationAuthorityData} from '.';
import {Model} from '../web';

export class ExaminationAuthority extends Model {
  static entity = 'examination-authorities';
  declare public data: ExaminationAuthorityData;

  get title() {
    return `${this.data.name} (${this.data.id})`;
  }
}
