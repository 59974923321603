import Joi from 'joi';

const constraints = {
  title: {maxLength: 60},
  description: {maxLength: 30, minLength: 2},
  number: {minLength: 6},
};

export const learningObjectiveValidation = {
  constraints,
  schema: Joi.object({
    title: Joi.string().label('title').required(),
    description: Joi.string().label('description').required(),
    parentId: Joi.number().allow(null).label('parentId').optional(),
    learningObjectiveTreeId: Joi.number().label('learningObjectiveTreeId').required(),
    number: Joi.string().label('number').required(),
  }),
};
