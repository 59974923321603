import {InvoiceData} from '.';
import {Model} from '../web';
import {invoiceValidation} from './invoice.validation';

export class Invoice extends Model {
  static entity = 'invoices';
  static validation = invoiceValidation;

  declare public data: InvoiceData;
}
