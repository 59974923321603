import {TodoData} from '.';
import {Model} from '../web';

export class Todo extends Model {
  static entity = 'todos';
  declare public data: TodoData;

  toCalendarItem() {
    return {
      id: this.data.id,
      type: 'todo',
      color: 'red',
      details: 'red todo',
      name: this.data.title,
      description: this.data.description,
      start: new Date(this.data.startDate),
      end: new Date(this.data.endDate),
    };
  }
}
