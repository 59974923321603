import {ResourceType, TaskType, TicketType} from '../../enums';

export function getTaskIcon(type: string | TaskType) {
  switch (type) {
    case TaskType.Review:
      return 'mdi-chat';
    case TaskType.Default:
      return 'mdi-wrench';
    case TaskType.Translation:
      return 'mdi-translate';
    case TaskType.Graphic:
      return 'mdi-image';
    case TaskType.Controlling:
      return 'mdi-finance';
  }
}

export function getTicketIcon(type: string | TicketType) {
  switch (type) {
    case TicketType.Feedback:
      return 'mdi-chat';
    case TicketType.Support:
      return 'mdi-wrench';
    case TicketType.Task:
      return 'mdi-checkbox-marked-outline';
    case TicketType.Graphics:
      return 'mdi-image-area';
    case TicketType.Review:
      return 'mdi-file-eye';
    case TicketType.Translation:
      return 'mdi-translate';
    case TicketType.Submission:
      return 'mdi-asterisk-circle-outline';
  }
}

export function getResourceTypeIcon(type: ResourceType) {
  switch (type) {
    case ResourceType.Audio:
      return 'mdi-volume-high';
    case ResourceType.PDF:
      return 'mdi-file-pdf-box';
    case ResourceType.Image:
      return 'mdi-image';
    case ResourceType.Website:
      return 'mdi-web';
    case ResourceType.Video:
      return 'mdi-video';
    case ResourceType.SketchFab:
      return 'mdi-video-3d';
    case ResourceType.File:
      return 'mdi-file';
  }
}
