import {UserData, userValidation} from '.';
import {Model} from '../web';

export class User extends Model {
  static entity = 'users';
  static validation = userValidation;
  declare public data: UserData;

  get name(): string {
    return this.data.username || `${User.$tc('p.user')} (ID: ${this.data.id})`;
  }

  static async changePassword(userId: number, password: string) {
    await this.$api.put(`/api/users/${userId}/change-password`, {password});
  }


  static async fetchLTMSAccount(userId: number) {
    const response = await this.$api.get(`/api/users/${userId}/ltms-account`);
    return response.data;
  }

  static async fetchELearningAccount(userId: number) {
    const response = await this.$api.get(`/api/users/${userId}/elearning-account`);
    return response.data;
  }

  static async fetchOrganizationAccount(userId: number) {
    const response = await this.$api.get(`/api/users/${userId}/organization-account`);
    return response.data;
  }

  static async fetchContactProfile(userId: number) {
    const response = await this.$api.get(`/api/users/${userId}/contact-profile`);
    return response.data;
  }

  static async fetchAuthorProfile(userId: number) {
    const response = await this.$api.get(`/api/users/${userId}/author-profile`);
    return response.data;
  }

  get hasLTMSAccount(): boolean {
    return !!this.data.ltmsAccountId;
  }

  get hasELearningAccount(): boolean {
    return !!this.data.eLearningAccountId;
  }

  get hasContactProfile(): boolean {
    return !!this.data.contactProfileId;
  }

  get hasAuthorProfile(): boolean {
    return !!this.data.authorProfileId;
  }
}
