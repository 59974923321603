import {QuestionnaireSubmission, QuestionnaireSubmissionDto} from '.';
import {QuestionData} from '../question';

export interface QuestionnaireData {
  id: number;
  questionnaireTemplateId: number;
  userId: number;
  title: string;
  type: string;
  settings: any;
  state: QuestionnaireState;
  questionIds: number[];
  submissions: QuestionnaireSubmission[];
  licenceId: number,
  learningObjectiveTreeId: number,
  questionCatalogueId: number,
  createdAt: Date;
  updatedAt: Date;
}

export enum QuestionnaireState {
  DEFAULT = 'default',
  ARCHIVED = 'archived',
}

export interface QuestionnaireResults {
  data: QuestionnaireData,
  questions: QuestionData[],
}

export class QuestionnaireDto {
  id!: number;
  questionnaireTemplateId?: number;
  userId!: number;
  title!: string;
  type!: string;
  settings!: any;
  state!: QuestionnaireState;
  questionIds!: number[];
  submissions!: QuestionnaireSubmissionDto[];
  licenceId!: number;
  learningObjectiveTreeId!: number;
  questionCatalogueId!: number;
  createdAt!: Date;
  updatedAt!: Date;
}
