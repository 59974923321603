import tlds from '@sideway/address/lib/tlds';
import Joi from 'joi';
import {commonValidation} from '../../validation';

const constraints = {
  avatar: {maxLength: 150},
  email: {maxLength: 100},
  username: {minLength: 2, maxLength: 100},
  password: {minLength: 6},
  status: {maxLength: 10},
  language: commonValidation.constraints.language,
  note: {maxLength: 1000},
};

export const userValidation = {
  constraints,
  schema: Joi.object({
    email: Joi.string().label('email')
      .email({tlds: {allow: tlds}})
      .max(constraints.email.maxLength)
      .required(),
    avatar: Joi.string().label('avatar')
      .allow(null, '')
      .max(constraints.email.maxLength),
    status: Joi.string().label('status')
      .max(constraints.status.maxLength)
      .allow(null, ''),
    username: Joi.string().label('username')
      .min(constraints.username.minLength)
      .max(constraints.username.maxLength)
      .required(),
    language: commonValidation.rules.language,
    note: Joi.string().label('note')
      .allow(null, '')
      .max(constraints.note.maxLength),
  }),
  createSchema: Joi.object({
    email: Joi.string().label('email')
      .email({tlds: {allow: tlds}})
      .max(constraints.email.maxLength)
      .required(),
    avatar: Joi.string().label('avatar')
      .allow(null, '')
      .max(constraints.email.maxLength),
    status: Joi.string().label('status')
      .max(constraints.status.maxLength)
      .allow(null, ''),
    username: Joi.string().label('username')
      .min(constraints.username.minLength)
      .max(constraints.username.maxLength)
      .required(),
    language: commonValidation.rules.language,
    note: Joi.string().label('note')
      .allow(null, '')
      .max(constraints.note.maxLength),
    password: Joi.string().label('password')
      .min(constraints.password.minLength)
      .required(),
  }),
};
