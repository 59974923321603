import Joi from 'joi';
import {getValidationMessages} from '../../validation';

export class FormHelper {
  static buildRule(schema: Joi.Schema, locale: string) {
    const messages = getValidationMessages(locale);
    return [(input: any) => {
      const result = schema.validate(input, {messages});
      return result.error?.message || true;
    }];
  }

  static buildRules(schema: { [name: string]: Joi.Schema }, locale: string): any {
    return Object.keys(schema).reduce((obj, key) => Object.assign(obj, {[key]: FormHelper.buildRule(schema[key], locale)}), {});
  }
}
