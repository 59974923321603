import Joi from 'joi';

const constraints = {
  name: {},
  description: {},
};

export const learningObjectiveTreeValidation = {
  constraints,
  schema: Joi.object({
    name: Joi.string().label('name').required(),
    description: Joi.string().label('description').required(),
    licences: Joi.array().items(Joi.number().positive()).label('licences').required(),
  }),
};
