import Joi from 'joi';
import {ProductType} from '../../enums';

const constraints = {
  name: {maxLength: 120},
};

export const productValidation = {
  constraints,
  schema: Joi.object({
    name: Joi.string().label('name')
      .max(constraints.name.maxLength)
      .required(),
    description: Joi.string().label('description').allow('').optional(),
    uploadIdentifier: Joi.string().label('uploadIdentifier').allow(null, '').optional(),
    type: Joi.string().label('type').valid(...Object.values(ProductType)).required(),
    duration: Joi.number().positive().required(),
    price: Joi.number().positive().allow(0).required(),
    reducedTax: Joi.boolean().required(),
    sourceId: Joi.number().positive().integer().required(),
    questionCatalogueId: Joi.number().positive().required(),
    image: Joi.string().valid(null).optional(),
  }),
};
