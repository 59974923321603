import {ResourceData, resourceValidation} from '.';
import {ResourceType} from '../../enums';
import {Model} from '../web';

export class Resource extends Model {
  static entity = 'resources';
  static validation = resourceValidation;
  declare public data: ResourceData;

  static get translationKey() {
    return 'resource';
  }

  get icon() {
    switch (this.data.type) {
      case ResourceType.Image:
        return 'mdi-image';
      case ResourceType.Video:
        return 'mdi-video';
      case ResourceType.Audio:
        return 'mdi-music';
    }
  }
}
